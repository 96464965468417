import Vue from "vue";
import VueRouter from "vue-router";

import { isWX } from "@/assets/js/common";
import 'vant/es/toast/style';
// import { Toast } from 'vant';
// import { Notify } from 'vant';


//import index from "../views/index.vue";

import product from "../views/lr/product.vue";
import productDet from "../views/lr/productDetail.vue";
import download from "../views/lr/download.vue";
import about from "../views/lr/about.vue";
import home from "../views/Home.vue";

// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "home",
		component: home,
		meta: {
			title: "广州青玄科技有限公司  |  anyapps 官网",
		},
	},
	{
		path: "/product",
		name: "product",
		component: product,
		meta: {
			title: "广州青玄科技有限公司  |  anyapps 官网",
		},
	},

	{
		path: "/productDet",
		name: "productDet",
		component: productDet,
		meta: {
			title: "广州青玄科技有限公司  |  anyapps 官网",
		},
	},
	{
		path: "/download",
		name: "download",
		component: download,
		meta: {
			title: "广州青玄科技有限公司  |  anyapps 官网",
		},
	},
	{
		path: "/about",
		name: "about",
		component: about,
		meta: {
			title: "广州青玄科技有限公司  |  anyapps 官网",
		},
	}/*,
	{
		path: "/downloadori",
		// 直接指向 HTML 文件
		component:  () => import('@/views/downloadori/index.html')
	}*/
];
// title

const router = new VueRouter({
	routes,
	// 跳转页面后 回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;

//title
const defaultTitle = "默认 title";
router.beforeEach((to, from, next) => {
	if(to.path === '/app-release.apk'){
		if(isWX()){
			//Toast('点击右上角“···”，选择“在浏览器中打开链接”，即可下载app');
			//Notify({ type: 'success', message: '点击右上角“···”，选择“在浏览器中打开链接”，即可下载app',duration: 0,position: "middle" })
			// 重定向到指定路径
			//next(window.location.origin + '/downloadori/index.html');
			window.location.href = window.location.origin + '/downloadori/index.html';
			return;
		}
	}
	document.title = to.meta.title ? to.meta.title : defaultTitle;
	next();
});
