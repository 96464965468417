<template>
  <div class="home">
	<!-- header -->
	<div class="header">
		<div class="header_content">
			<div class="header_logo-menu">
				<div class="header_logo">
				</div>
				<div class="header_menu">
					<div class="header_menu_item" :class="{'header_menu_item_activite': menuItemActivite == 'top'}" @click="toSection('top')">首页</div>
					<div class="header_menu_item" :class="{'header_menu_item_activite': menuItemActivite == 'team'}" @click="toSection('team')">团队</div>
					<div class="header_menu_item" :class="{'header_menu_item_activite': menuItemActivite == 'case'}" @click="toSection('case')">案例</div>
				</div>
			</div>
			<div class="header_concat">
				<div class="header_phone">
					<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/phone.png" />
					<span>18320738390</span>
				</div>
				<div class="header_wx">
					<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/wx.png" />
					<span>微信咨询</span>
				</div>
			</div>
			
		</div>
	</div>
	
	<!-- company -->
	<div class="company" id="top">
		<div class="company_top">
			<div class="company_title">
				<span class="company_title_content_1">专业可信赖的，</span>
				<span class="company_title_content_2">全平台开发</span>
				<span class="company_title_content_3">服务商</span>
			</div>
			<div class="company_content">
				<div class="company_content_1">
					<div class="company_content_top">
						<span class="company_number">12</span>
						<span class="company_number2">年+</span>
					</div>
					<div class="company_bottom">
						<span class="company_number3">专注软件开发</span>
					</div>
				</div>
				<div class="company_content_2">
					<div class="company_content_top">
						<span class="company_number">10</span>
						<span class="company_number2">年+</span>
					</div>
					<div class="company_bottom">
						<span class="company_number3">人均研发经验</span>
					</div>
				</div>
				<div class="company_content_3">
					<div class="company_content_top">
						<span class="company_number">360</span>
						<span class="company_number2">°</span>
					</div>
					<div class="company_bottom">
						<span class="company_number3">交付&售后保障</span>
					</div>
				</div>
				<div class="company_content_4">
					<div class="company_content_top">
						<span class="company_number">50</span>
						<span class="company_number2">+</span>
					</div>
					<div class="company_bottom">
						<span class="company_number3">各行业服务案例</span>
					</div>
				</div>
			</div>
		</div>
		<div class="company_qrcode">
			<div class="company_qr_img">
				<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/qr_point.png" />
			</div>
		</div>
		
	</div>
	
	<!-- -->
	<div class="server">
		<div class="server_top_title">
			<span>核心服务</span>
		</div>
		<div class="server_content">
			<div class="server_content_1">
				<div class="server_top">
					<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/coro_server_1.png" />
				</div>
				<div class="server_title">
					<span>APP开发</span>
				</div>
				<div class="server_memo">
					<span>IOS/Andriod源生APP开发跨平台APP开发</span>
				</div>
				<div class="server_btn server_link_btn" @click="toSection('case')" v-if="false">
					<span>查看案例</span>
				</div>
			</div>
			
			<div class="server_content_2">
				<div class="server_top">
					<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/coro_server_2.png" />
				</div>
				<div class="server_title">
					<span>小程序开发</span>
				</div>
				<div class="server_memo">
					<span>为您的智能硬件、私域电商等，提供一站式小程序定制开发 <br/>
					</span>
				</div>
				<div class="server_btn" @click="toSection('case')" v-if="false">
					<span>查看案例</span>
				</div>
			</div>
			
			<div class="server_content_3">
				<div class="server_top">
					<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/coro_server_3.png" />
				</div>
				<div class="server_title">
					<span>网站开发</span>
				</div>
				<div class="server_memo">
					<span>企业官网、PC端零售商城系统、品牌官网以及配套的管理后台</span>
				</div>
				<div class="server_btn" @click="toSection('case')" v-if="false">
					<span>查看案例</span>
				</div>
			</div>
			
			<div class="server_content_4">
				<div class="server_top">
					<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/coro_server_4.png" />
				</div>
				<div class="server_title">
					<span>各行业软件开发</span>
				</div>
				<div class="server_memo">
					<span>ERP、在线商城、客户管理、物联网、数字家具等商业系统的数字化</span>
				</div>
				<div class="server_btn" @click="toSection('case')" v-if="false">
					<span>查看案例</span>
				</div>
			</div>
			
		</div>
	</div>
	
	<!-- 服务行业 -->
	<div class="business">
		<div class="business_top">
			<span>服务行业广泛</span>
		</div>
		<div class="business_content">
			<div class="business_left">
				<div class="business_left_title">
					智能硬件
				</div>
				<div class="business_left_icon">
					<div class="business_left_row">
						<div class="business_left_column_1">
							<div class="business_left_column_icon">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/business_watch.png" alt=""/>
							</div>
							<div class="business_left_column_content">
								<div class="business_left_column_content_title">
									智能手环
								</div>
								<div class="business_left_column_content_memo">
									健康监测/跟踪
								</div>
							</div>
						</div>
						<div class="business_left_column_2">
							<div class="business_left_column_icon">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/business_health.png" alt=""/>
							</div>
							<div class="business_left_column_content">
								<div class="business_left_column_content_title">
									智慧医疗
								</div>
								<div class="business_left_column_content_memo">
									医疗监护/管理
								</div>
							</div>
						</div>
					</div>
					
					<div class="business_left_row">
						<div class="business_left_column_1">
							<div class="business_left_column_icon">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/business_temp.png" alt=""/>
							</div>
							<div class="business_left_column_content">
								<div class="business_left_column_content_title">
									智能体温计
								</div>
								<div class="business_left_column_content_memo">
									体温监测/报警
								</div>
							</div>
						</div>
						<div class="business_left_column_2">
							<div class="business_left_column_icon">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/business_closestool.png" alt=""/>
							</div>
							<div class="business_left_column_content">
								<div class="business_left_column_content_title">
									智能马桶
								</div>
								<div class="business_left_column_content_memo">
									清洁控制
								</div>
							</div>
						</div>
					</div>
					
					<div class="business_left_row">
						<div class="business_left_column_1">
							<div class="business_left_column_icon">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/business_cosmetic.png" alt=""/>
							</div>
							<div class="business_left_column_content">
								<div class="business_left_column_content_title">
									智能美容仪
								</div>
								<div class="business_left_column_content_memo">
									一键护理/皮肤分析
								</div>
							</div>
						</div>
						<div class="business_left_column_2" v-if="false">
							<div class="business_left_column_icon">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/business_law.png" alt=""/>
							</div>
							<div class="business_left_column_content">
								<div class="business_left_column_content_title">
									智慧xx
								</div>
								<div class="business_left_column_content_memo">
									xx/xxxx
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
			<div class="business_right">
				<div class="business_mall">
					<div class="business_mall_title">线上购物</div>
					<div class="business_mall_icon">
						<div class="business_mall_row">
							<div class="business_mall_column_1">
								<div class="business_mall_column_icon">
									<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/business_mall.png" alt=""/>
								</div>
								<div class="business_mall_column_content">
									<div class="business_mall_column_content_title">
										独立商城
									</div>
									<div class="business_mall_column_content_memo">
										销售/订单管理/评价系统
									</div>
								</div>
							</div>
							<div class="business_mall_column_2">
								<div class="business_mall_column_icon">
									<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/business_law.png" alt=""/>
								</div>
								<div class="business_mall_column_content">
									<div class="business_mall_column_content_title">
										线上私域
									</div>
									<div class="business_mall_column_content_memo">
										会员管理/后台管理
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
				
				<div class="business_company">
					<div class="business_company_title">企业办公</div>
					<div class="business_company_icon">
						<div class="business_company_row">
							<div class="business_company_column_1">
								<div class="business_company_column_icon">
									<img  src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/business_law.png" alt=""/>
								</div>
								<div class="business_company_column_content">
									<div class="business_company_column_content_title">
										法律咨询
									</div>
									<div class="business_company_column_content_memo">
										案件管理/官网后台/在线咨询
									</div>
								</div>
							</div>
							<div class="business_company_column_2">
								<div class="business_company_column_icon">
									<img  src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/business_inventory.png" alt=""/>
								</div>
								<div class="business_company_column_content">
									<div class="business_company_column_content_title">
										进销存
									</div>
									<div class="business_company_column_content_memo">
										采购/销售/库存管理
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
				
				
				
				
			</div>
		</div>
	</div>
	
	
	<!-- 我们的优势 -->
	<div class="edge" id="team">
		<div class="edge_div">
			<div class="edge_title">我们的优势</div>
			<div class="edge_control">
				<div :class="showEdgeIndex == 1 ? 'edge_btn_activite' : 'edge_btn'" @click="changeEdgeIndex('1')">强大的技术团队</div>
				<div :class="showEdgeIndex == 2 ? 'edge_btn_activite' : 'edge_btn'" @click="changeEdgeIndex('2')">全面售后保障</div>
				<div :class="showEdgeIndex == 3 ? 'edge_btn_activite' : 'edge_btn'" @click="changeEdgeIndex('3')">深厚的技术积累</div>
			</div>
			<div class="edge_content" v-if="showEdgeIndex == 1">
				<div class="edge_content_memo">核心技术团队均来自大型互联网企业<br />可根据您的项目需求，为您专属定制方案<br />满足您全方位的产品需求</div>
				<div class="edge_content_statics">
					<div class="edge_content_statics_left">
						<div class="edge_content_statics_left_per">80%</div>
						<div class="edge_content_statics_left_memo">研发技术人员占比</div>
					</div>
					<div class="edge_content_statics_middle"></div>
					<div class="edge_content_statics_right">
						<div class="edge_content_statics_right_year">10年</div>
						<div class="edge_content_statics_right_memo">人均开发经验</div>
					</div>
				</div>
			</div>
			<div class="edge_content_2" v-if="showEdgeIndex == 2">
				<div class="edge_content_memo_2">提供360°全面完善的售后服务<br />支持远程或现场调试解决技术问题</div>
				<div class="edge_content_statics_2">
					<div class="edge_content_statics_left_2">
						<div class="edge_content_statics_left_per_2">360°</div>
						<div class="edge_content_statics_left_memo_2">全面保障产品体验</div>
					</div>
					<div class="edge_content_statics_middle_2"></div>
					<div class="edge_content_statics_right_2">
						<div class="edge_content_statics_right_year_2">6个月</div>
						<div class="edge_content_statics_right_memo_2">免费售后维护</div>
					</div>
				</div>
			</div>
			<div class="edge_content_3" v-if="showEdgeIndex == 3">
				<div class="edge_content_memo_3">
					精通&nbsp;Android&nbsp;开发、ios&nbsp;开发、全平台小程序开发windows开发、mac开发、unity3d开发以及c#.net&nbsp;开发<br /><br />精通c/c++、c#或java、kotlin、lua、go、js/typescript、object-c、swift等主流开发语言
				</div>
				<div class="edge_content_statics_3">
					<div class="edge_content_statics_left_3">
						<div class="edge_content_statics_left_per_3">全平台</div>
					</div>
					<div class="edge_content_statics_middle_3"></div>
					<div class="edge_content_statics_right_3">
						<div class="edge_content_statics_right_year_3">多语言</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>

	
	<!-- 标杆案例 -->
	<div class="case" id="case">
		<div class="case_title">标杆案例</div>
		<div class="case_content">
			<div class="swiper-container">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<div class="slide_box">
							<div class="slide_box_title">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_mdn_logo.png"/>
							</div>
							<div class="slide_box_img">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_mdn_pic.png" />
							</div>
							<div class="slide_box_memo">
								<span>健康数据检测系统<br />为医院病房护士打造，协助护士高效检测和管理患者每日健康数据和日常护理。</span>
							</div>
							<div class="slide_box_btn" @click="openPdfShow('https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/pdf/mdl.pdf')">
								<span>详细了解</span>
							</div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="slide_box">
							<div class="slide_box_title">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_xzt_logo.png" />
							</div>
							<div class="slide_box_img">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_xzt_pic.png" />
							</div>
							<div class="slide_box_memo">
								<span>宪章堂应用平台是给广东宪章堂文化投资集团有限公司设计的一个电商平台，提供客户的在线商品销售，其客户端包括PC端，小程序端，安卓和IOS等。</span>
							</div>
							<div class="slide_box_btn" @click="openPdfShow('https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/pdf/xzt.pdf')">
								<span>详细了解</span>
							</div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="slide_box">
							<div class="slide_box_title">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_lr_logo.png"/>
							</div>
							<div class="slide_box_img">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_lr_pic.png" />
							</div>
							<div class="slide_box_memo">
								<span>
									通过智能体温硬件的蓝牙数据传输，在手机APP就能实时查看体温数据&nbsp;了解体温的升降趋势，并生成记录，达成安全、高效的体温监控。
								</span>
							</div>
							<div class="slide_box_btn" @click="openPdfShow('https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/pdf/lr.pdf')">
								<span>详细了解</span>
							</div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="slide_box">
							<div class="slide_box_title">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_zq_logo.png"/>
							</div>
							<div class="slide_box_img">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_zq_pic.png" />
							</div>
							<div class="slide_box_memo">
								<span>
									广州市正奇法律咨询服务有限公司的业务系统，支持客户的信息管理，案件进度管理，风控管理，合同管理，各类&nbsp;统计查询以及公司成绩及动态展现。
								</span>
							</div>
							<div class="slide_box_btn" @click="openPdfShow('https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/pdf/zq.pdf')">
								<span>详细了解</span>
							</div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="slide_box">
							<div class="slide_box_title">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_zskh_logo.png"/>
							</div>
							<div class="slide_box_img">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_zskh_pic.png" />
							</div>
							<div class="slide_box_memo">
								<span>
									掌上快绘，一台手机即可实现选砖、看图、传图、找方案、设计出图，让销售人员快速找到店铺商品、商品相关效果图和设计方案。
								</span>
							</div>
							<div class="slide_box_btn" @click="openPdfShow('https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/pdf/zskh.pdf')">
								<span>详细了解</span>
							</div>
						</div>
					</div>					
					<div class="swiper-slide">
						<div class="slide_box">
							<div class="slide_box_title">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_vpn_logo.png"/>
							</div>
							<div class="slide_box_img">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_vpn_pic.png" />
							</div>
							<div class="slide_box_memo">
								<span>
									SASE终端应用，有企业服务访问、因特网服务访问，安全、快速连接，保护您的网络隐私
								</span>
							</div>
							<div class="slide_box_btn" @click="openPdfShow('https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/pdf/vpn.pdf')">
								<span>详细了解</span>
							</div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="slide_box">
							<div class="slide_box_title">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_electric_logo.png"/>
							</div>
							<div class="slide_box_img">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_electric_pic.png" />
							</div>
							<div class="slide_box_memo">
								<span>
									3D电路教学平台，提供逼真的家庭室内场景，支持自由搭建丰富的组件、高自由度的操作体验，让教学变得高效。<br />此外还有力学、电学、光学等多个探究平台。
								</span>
							</div>
							<div class="slide_box_btn" @click="openPdfShow('https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/pdf/electric.pdf')">
								<span>详细了解</span>
							</div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="slide_box">
							<div class="slide_box_title">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_watch_logo.png"/>
							</div>
							<div class="slide_box_img">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_watch_pic.png" />
							</div>
							<div class="slide_box_memo">
								<span>
									智能手表对讲机应用，拥有实时对讲、频道设置、群消息、频道监听、账户管理等功能。即刻沟通，时刻在线。小巧智能，一切尽在手腕。
								</span>
							</div>
							<div class="slide_box_btn" @click="openPdfShow('https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/pdf/watch.pdf')">
								<span>详细了解</span>
							</div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="slide_box">
							<div class="slide_box_title">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_lg_logo.png"/>
							</div>
							<div class="slide_box_img">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_lg_pic.png" />
							</div>
							<div class="slide_box_memo">
								<span>
									深圳理工大学建设工程项目官网，适配PC端和手机端，介绍项目概况、区位，并进行项目推演。可以按月份查看，一标段、二标段、三标段对应地图。
								</span>
							</div>
							<div class="slide_box_btn" @click="openPdfShow('https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/pdf/lg.pdf')">
								<span>详细了解</span>
							</div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="slide_box">
							<div class="slide_box_title">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_flower_logo.png"/>
							</div>
							<div class="slide_box_img">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_flower_pic.png" />
							</div>
							<div class="slide_box_memo">
								<span>
									花至美容仪app，可以连接和控制多款花至美容仪，提供定制化美容护理方案，提供视频操作指导，支持自定义护理细节，生成护理报告，为您的美丽保驾护航。
								</span>
							</div>
							<div class="slide_box_btn" @click="openPdfShow('https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/pdf/flower.pdf')">
								<span>详细了解</span>
							</div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="slide_box">
							<div class="slide_box_title">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_thunderbird_logo.png"/>
							</div>
							<div class="slide_box_img">
								<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/V2/case_thunderbird_pic.png" />
							</div>
							<div class="slide_box_memo">
								<span>
									雷鸟AR眼镜应用，拥有拍照、通话、短信、实时对话翻译、英语学习、媒体播放、通信等丰富功能，为您的工作、生活、学习带来无尽的便利。
								</span>
							</div>
							<div class="slide_box_btn" @click="openPdfShow('https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/pdf/thunderbird.pdf')">
								<span>详细了解</span>
							</div>
						</div>
					</div>
					
				</div>
				<div class="swiper-button-prev"></div>
				<div class="swiper-button-next"></div>
				<div class="swiper-pagination"></div>
			</div>
		</div>
	</div>
	
	<div class="partner">
		<div class="partner_title">服务过的企业</div>
		<div class="partner_content">
			<div class="partner_content_row">
				<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/partner_r1_c1.png" style="width:319px;" />
				<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/partner_r1_c2.png" style="width:278px;" />
				<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/partner_r1_c3.png" style="width:207px;" />
				<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/partner_r1_c4.png" style="width:290px;" />
				<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/partner_r1_c5.png" style="width:270px;" />
				
			</div>
			<div class="partner_content_row">
				<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/partner_r2_c1.png" style="width:540px;" />
				<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/partner_r2_c2.png" style="width:306px;" />
				<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/partner_r2_c3.png" style="width:319px;" />
				<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/partner_r2_c4.png" style="width:215px;" />
				
			</div>
			<div class="partner_content_row">
				<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/partner_r3_c1.png" style="width:283px;" />
				<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/partner_r3_c2.png" style="width:459px;" />
				<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/partner_r3_c3.png" style="width:154px;" />
				<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/partner_r3_c4.png" style="width:247px;" />
				<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/partner_r3_c5.png" style="width:219px;" />
				
			</div>
		</div>
	</div>
	
	<div class="qa">
		<div class="qa_title">大中小企业，都有适合您的解决方案</div>
		<div class="qa_content">
			<div class="qa_content_left">
				<div class="qa_content_left_r1">留言咨询</div>
				<div class="qa_content_left_r2">
					右侧提交您的咨询内容<br/>我们将尽快安排最适合的技术联系您
				</div>
				<div class="qa_content_left_r4">
					<div class="qa_content_left_r4_left">
						<img src="https://anyappsweb.oss-cn-hangzhou.aliyuncs.com/home/qrcode.png" />
					</div>
					<div class="qa_content_left_r4_right">
						<div class="qa_content_left_r4_right_1">立即咨询</div>
						<div class="qa_content_left_r4_right_2">电话咨询：18320738390<br />微信：18320738390<br />QQ：173838423
						</div>
					</div>
				</div>
			</div>
			<div class="qa_content_right">
				<div class="qa_content_right_r1">
					<div class="qa_content_right_r1_c">
						姓名<br/>
						<input disabled="true" placeholder="请输入您的姓名" />
					</div>
					<div class="qa_content_right_r1_c qa_content_right_r1_c2">
						电话 <span class="qa_content_right_require">*</span><br/>
						<input disabled="true" placeholder="请输入您的电话" />
					</div>
					<div class="qa_content_right_r1_c qa_content_right_r1_c2">
						公司<br/>
						<input disabled="true" placeholder="请输入您的公司" />
					</div>
				</div>
				<div class="qa_content_right_r2">
					咨询内容 <span class="qa_content_right_require">*</span><br/>
					<input disabled="true" type="text" placeholder="请输入您的咨询内容" />
				</div>
				<div class="qa_content_right_r3">
					<div class="qa_content_right_btn">
						<span>提交</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<el-dialog
		title="pdf预览"
		:visible.sync="pdfVisible"
		width="800px;"
		@closed="close"
		append-to-body
	>
		<!-- <pdf-viewer
			:srcList="Url"
			style="width: 150px; height: 100px"
		></pdf-viewer> -->
		<!-- <pdf :pdfurl="dialogSrc" @closePdf="close"></pdf> -->
		<!-- <pdfShow style="width: 400px;height: 500px;border: 1px solid #ccc;overflow-y: scroll;" /> -->
	</el-dialog>
	
  </div>
</template>

<script>
// @ is an alias to /src
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

import { Base64 } from 'js-base64';

/* import pdfViewer from "./pdfViewer.vue"; */
/* import vuePdfViewer from "./vuePdfViewer.vue"; */
/* import pdfViewer from '@/views/vuePdfViewIndex.vue'; */
/* import pdf from './pdfindex.vue'; */
/* import pdfShow from "./pdfShow.vue"; */

export default {
  name: 'Home',
  components: {
	/* swiper, swiperSlide */
	/* pdfViewer */
	/* vuePdfViewer */
	/* pdfShow */
  },
  data(){
	return {
		menuItemActivite: 'top',
		pdfVisible: false,
		dialogSrc: "",
		showEdgeIndex: 1,
		swiperOption: {
			slidesPerView: 1.2, //显示slide的数量
			centeredSlides: true, //居中显示
			loop: true, //循环
			initialSlide: 0, //初始索引
			spaceBetween: 16, //间距
		},
	};
  },
  mounted(){
	this.swiper = new Swiper(".swiper-container", {
		width: 448,
		slidesPerView: 5,
		spaceBetween: 30,
		centeredSlides: true,
		loop: true,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		}
	});
  },
  methods: {
	changeEdgeIndex(index){
		this.showEdgeIndex = index;
	},
	// 轮播图滑动事件
	slideChange(){
		this.activeIndex = this.$refs.mySwiper.swiper.realIndex;
		console.log('阿啦啦啦啦啦',this.$refs.mySwiper.swiper.realIndex);
		console.log('轮播图当前展示项的真实下标',this.activeIndex);
	},
	toSection(id){
		this.menuItemActivite = id;
		window.requestAnimationFrame(() => {
			let el = document.getElementById(id);
			if(!el){
				return;
			}
			this.$nextTick(function(){
				// window.scrollTo({"behavior":"smooth","top":el&&el.offsetTop});
				el.scrollIntoView({behavior: "smooth",// 平滑过渡
					block: "start"  // 上边框与视窗顶部平齐。默认值
				});
			})
		});
	
	},
	maxScreen(a) {
		if (a) {
			this.height = '500px';
		} else {
			this.height = window.innerHeight - 65 + 'px';
		}
	},
	close() {
		this.dialogSrc = '';
		this.pdfVisible = false;
	},
	openPdfShow(pdfUrl){
		if(!pdfUrl || pdfUrl == ''){
			this.$message.info("功能暂未提供，敬请等待!");
			return;
		}
		/* this.dialogSrc = pdfUrl;
		this.pdfVisible = true; */
		// window.open(pdfUrl,"_blank")
		window.open('http://anyapps.net/preview/onlinePreview?url='+encodeURIComponent(Base64.encode(pdfUrl)));
	}
  }
}
</script>

<style scoped>
@import "../assets/css/home.css";
</style>

<style scoped lang="css">
		.swiper-slide {
			width: 28rem!important;
			height: 31rem!important;
		}
		.swiper-slide-duplicate {
			width: 28rem!important;
			height: 31rem!important;
		}
</style>