<template>
	<!-- star -->
	<div class="youjiaopage_wrap">
		
		<lr-header @toSection="toSection"></lr-header>
		
		<div class="about_top">
			<div class="about_top_title">公司介绍</div>
			<div class="about_top_title_remark">企业发展 · 科技先行</div>
		</div>
		
		<div class="about">
			<div class="about_left">
				<div class="about_brand">BRAND</div>
				<div class="about_title">关于企业</div>
				<div class="about_company">
					哈尔滨了然科技开发有限公司是一家专业经营医疗器械的企业，公司获国家药品监督管理局监制颁发《医疗器械经营企业许可证》、《第二类医疗器械经营备案凭证》。公司成立于2019年2月27日，注册资本666万元，注册地址哈尔滨高新技术产业开发区科技创新城巨宝一路457号2单元214室。公司成立5余年，销售产品包括脑电测量仪、心肺复苏机、血细胞分离机等，在同行业当中享有盛誉，公司一直秉承“企业发展，科技先行”的理念，精心打造企业团队，为企业全面振兴打好基础。
				</div>
			</div>
			<div class="about_right">
				<img src="../../assets/img/lr/icon-play.png" alt=""/>
			</div>
		</div>
		
		<div class="static"> 
			<div class="static-1">
				<div class="static-1-1">
					<div class="static-1-1-1">5</div>
					<div class="static-1-1-2">年+</div>
				</div>
				<div class="static-1-2">专注医疗器械开发</div>
			</div>
			<div class="static-2">
				<div class="static-2-1">
					<div class="static-2-1-1">10</div>
					<div class="static-2-1-2">年+</div>
				</div>
				<div class="static-2-2">人均研发经验</div>
			</div>
			<div class="static-3">
				<div class="static-3-1">
					<div class="static-3-1-1">360</div>
					<div class="static-3-1-2">°</div>
				</div>
				<div class="static-3-2">交付&售后保障</div>
			</div>
			<div class="static-4">
				<div class="static-4-1">
					<div class="static-4-1-1">80</div>
					<div class="static-4-1-2">+</div>
				</div>
				<div class="static-4-2">医疗行业服务案例</div>
			</div>
		</div>
		
		<div class="culture">
			<div class="culture-title-box">
				<div class="culture-title-en">culture</div>
				<div class="culture-title">企业文化</div>
			</div>
			<div class="culture-group">
				<div class="culture-group-item">
					<div class="culture-group-item-circle"></div>
					<div class="culture-group-item-title">科技先行</div>
					<div class="culture-group-item-remark">专注科技，注重研发</div>
				</div>
				<div class="culture-group-item"></div>
				<div class="culture-group-item"></div>
			</div>
		</div>
		
		<!-- footer -->		
		<lr-footer id="footer" :footerLogo="logo" :companyName="companyName" :companyNameEn="companyNameEn"
			:companyTel="companyTel" :company-email="companyEmail" :companyPhone="companyPhone"></lr-footer>
			
		<!-- end -->
	</div>
</template>

<script>
import lrFooter from "../../components/lrfooter.vue";
import lrHeader from "../../components/lrheader.vue";


export default {
	name: "about",
	components: {
		lrHeader,
		lrFooter
	},
	data() {
		return {
			itemList:[{
				bgPath: require('../../assets/img/lr/bg_product_item.png'),
				title: "智能体温计",
				content: "APP实时监测 | 高低温随时报警"
			},{
				bgPath:require('../../assets/img/lr/bg_product_item.png'),
				title: "智能体温计",
				content: "APP实时监测 | 高低温随时报警"
			},{
				bgPath:require('../../assets/img/lr/bg_product_item.png'),
				title: "智能体温计",
				content: "APP实时监测 | 高低温随时报警"
			},{
				bgPath:require('../../assets/img/lr/bg_product_item.png'),
				title: "智能体温计",
				content: "APP实时监测 | 高低温随时报警"
			}],
			logo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABECAYAAADDRGZtAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAR5SURBVGiB7Vrbdds4FJy7x//LDpYlaCtYuoIoFVipIEoFlipwUoHkCpxUILoCKQ2E3AqiNLCzH7iIQBCk+BLF5GjO8ZGJ5wye9wIARgDJhOQLyYzkfyS/kdyRXIxRf2+QjEhuWI89ydm1uVZCRWRnRFh8n6wYkk8BshuS7zVuF4iPr827AJIzj2QWIkly5aVbjc+2BgGCcU3avZNuPyLN86BZoSx2Z9IuXMVd67zrmvEM3Il7sP94PXMUkSOA3M1IMhaRQlgT/NE2Qwf8AMxeAiBz/p40Ph+ikjGEjIKbkKnhJmRquAmZGn4bIYWdXU3pCMARQK477y8B30SZAdjYD5IpgFcAn0XkgAmjMLREZAtg7QQlAB4B7NV/SEZj1hKlOSIiKxTFWCQAdlMVFJzsKuYeYYMugRH0QjK6GLOWqFy1RCQF8DeAbUWSOcyQm4SvXbv8ishRRN7B9E4aSBLD9M5icGYt0WgfEZFURO5hBH2EWZ4tIgCbax8ctPIQdbilJNcwS3UC4C8YMTMM5CR1QSdXVzfKlGSu3/mAnDqhsRCd1DMAb2DmxsyJA4xv/vZaomqF6Lh/hBlC8ZmyPolIPtaS7JhTAHAICtENzwo4hxxmEYAe/XzoS7IKujo+eLwOAO59ozGGsbXchFXIATyLyErz7WB6La9J71oMjW03PYF8QHlUrHXzLiZmM2T+vuGcFmb6nTnpixWVScZe+bEXl3nxGc0ZckHUnY7pHYqHaj5ymA3xWZfgQgM4eT/XEE5Q7OlcjdQ6zLXuA4CvAFK/fos7AEucJk3u/NoCXqtMeG2V907QlxpSCcy8s0hRbf5AGzhSDpZHXpX+TsfYqoZAHR5xaoTK1uqIRH+XNkD9o+dQT3Z2dbXF5k7Qc9eyKvAE02u+f7TReeLW3ctnf0CxN7Y9ygrhCGDjuBSpExcDeFHfKAL6CUmcCt/1KMfHz00OQExy4RmtqcdhTzLuI8SuVOuBzRIr5FV/39gIT9Aap1Xysc/9SAQj4mOPMiohIluSQevCWuFuWJ8eKe+qw2MNIGri63TukUv1hFfHVkWcNUQvdfU2GJr2+m9zZHoTMjXchEwNlxIy+lHq4ELU0eoj5Ol8kjIGFeIcWrjIz2Tzn3HMSHYSMwhoHpp99/zrhRO/8eL21gSv8M2TawnxDy+WNXHuQcLMEeOi9lXRJYVkIRKBnvDx8+UcyaUXF48tIvIIPFQQq8JO0/u9shhbyDzUkiy/W6zDLJBnU1uxg6FWrT/dD8djTFqUEVqy46aZL72zt7mn98/WWuUfSsi/7gdP94p5izLsIaB74jm6EP8k0pL51DB/6lxJBN9DjgZvkmYV4SFkzkRv/Mz2kkIWHomVhkcsPp/1RcSaLmbRMrjOhqhkMo+ou7snNM/JN9rycyduOiaKEvL3E9K8g6+0hrUnfRuttdEo/agHiS1RNsWP0KsBmDe/dlInKO8VW32kcH1UtHITrK7NvQQd97uGAjJ61wRtMfjQ8sHTlds/MMMoxsmZOiBwndcF/wO88pURM7J72gAAAABJRU5ErkJggg==",
			companyName:"哈尔滨了然科技开发有限公司",
			companyNameEn: "Harbin Liaoran Technology Development Co.",
			sysName: "竹松兰",
			companyTel: "0451-84520036",
			companyPhone: "13163662080",
			companyEmail: "0451-84520036"
		};
	},
	methods: {
		jumpTo(url){
			this.$router.push(url);
		},
		toSection(id){
			window.requestAnimationFrame(() => {
				let el = document.getElementById(id);
				this.$nextTick(function(){
					// window.scrollTo({"behavior":"smooth","top":el&&el.offsetTop});
					el.scrollIntoView({behavior: "smooth",// 平滑过渡
						block: "start"  // 上边框与视窗顶部平齐。默认值
					});
				})
			});
			
		}
	},
};
</script>


<style scoped>
.about_top {
	width: 100%;
	height: 18.75rem;
	background: url(../../assets/img/lr/bg_about_top.png) 100% no-repeat;
	background-size: 100% 100%;
	
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.about_top_title {
	height: 3.9375rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 3rem;
	color: #3D3D3D;
	line-height: 3.9375rem;
	text-align: center;
	font-style: normal;
	text-transform: none;
}
.about_top_title_remark {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: center;
	font-style: normal;
	text-transform: none;
}

.about {
	padding-left: 17rem;
	padding-right: 15rem;
	/* width: 100%; */
	background-color: #ffffff;
	height: 39.625rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	
}
.about_left {
	margin-top: 5.3125rem;
	width: 50%;
}
.about_brand {
	height: 8.1875rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 6.1875rem;
	color: #DEE9FF;
	line-height: 8.1875rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.about_title {
	margin-top: -3.25rem;
	height: 3rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 2.25rem;
	color: #3D3D3D;
	line-height: 3rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.about_company {
	height: 23rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.about_right {
	width: 43rem;
	height: 24.1875rem;
	background: #3D3D3D;
	border-radius: 0px 0px 0px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.about_right img {
	width: 6rem;
	height: 6rem;
}

.static {
	padding-left: 17rem;
	padding-right: 15rem;
	display: flex;
	flex-direction: row;
	background: url(../../assets/img/lr/bg_about_static.png) 100% no-repeat;
	background-size: 100% 100%;
	
	justify-content: space-between;
	align-items: center;
	height: 12.8125rem;
}

.static-1 {
	display: flex;
	flex-direction: column;
}
.static-1-1 {
	display: flex;
	flex-direction: row;
	align-items: baseline;
}
.static-1-1-1 {
	height: 6.3125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 4.5rem;
	color: #3D3D3D;
	line-height: 6.3125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.static-1-1-2 {
	height: 3.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 2.25rem;
	color: #3D3D3D;
	line-height: 3.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.static-1-2 {
	height: 2.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.static-2-1 {
	display: flex;
	flex-direction: row;
	align-items: baseline;
}
.static-2-1-1 {
	height: 6.3125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 4.5rem;
	color: #3D3D3D;
	line-height: 6.3125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.static-2-1-2 {
	height: 3.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 2.25rem;
	color: #3D3D3D;
	line-height: 3.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.static-2-2 {
	height: 2.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.static-3-1 {
	display: flex;
	flex-direction: row;
	align-items: baseline;
}
.static-3-1-1 {
	height: 6.3125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 4.5rem;
	color: #3D3D3D;
	line-height: 6.3125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.static-3-1-2 {
	height: 3.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 2.25rem;
	color: #3D3D3D;
	line-height: 3.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.static-3-2 {
	height: 2.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.static-4-1 {
	display: flex;
	flex-direction: row;
	align-items: baseline;
}
.static-4-1-1 {
	height: 6.3125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 4.5rem;
	color: #3D3D3D;
	line-height: 6.3125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.static-4-1-2 {
	height: 3.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 2.25rem;
	color: #3D3D3D;
	line-height: 3.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.static-4-2 {
	height: 2.125rem;
	font-family: PingFang SC, PingFang SC;
	font-weight: 500;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.culture {
	width: 100%;
	height: 49.125rem;
	background: #F8F8F8;
	border-radius: 0px 0px 0px 0px;
}
.culture-title-box {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 10.8125rem;
	padding-top: 5.5625rem;
}
.culture-title-en {
	height: 8.1875rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 6.1875rem;
	color: #DEE9FF;
	line-height: 8.1875rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.culture-title {
	height: 3rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 2.25rem;
	color: #3D3D3D;
	line-height: 3rem;
	text-align: center;
	font-style: normal;
	text-transform: none;
	
	margin-top: -5rem;
}

.culture-group {
	padding-left: 17rem;
	padding-right: 15rem;
	display: flex;
	flex-direction: row;
	/* align-items: baseline; */
	justify-content:center;
}
.culture-group-item {
	width: 22.8125rem;
	height: 21.875rem;
	background: #FFFFFF;
	border-radius: 1.3125rem 1.3125rem 1.3125rem 1.3125rem;
	
	/* 内嵌菜单 */
	
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	margin-right: 3.875rem;
}

	
.culture-group-item-circle {
	width: 5.75rem;
	height: 92px;
	background: #5585CD;
	border-radius: 3.75rem 3.75rem 3.75rem 3.75rem;
	
	margin-top: 20px;
}

.culture-group-item-title {
	/* margin-top: 3rem; */
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: center;
	font-style: normal;
	text-transform: none;
}
.culture-group-item-remark {
	/* width: 162px; */
	height: 1.5rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: center;
	font-style: normal;
	text-transform: none;
}
</style>