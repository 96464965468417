<template>
	<!-- star -->
	<div class="youjiaopage_wrap">

		<lr-header @toSection="toSection"></lr-header>

		<div class="product_main">
			<div class="product_main_title">
				<div class="product_main_img">
					<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/bg_app_logo.png" alt=""  />
				</div>
				<div class="product_title">
					<span class="product_title_text">小竹智慧体温管家</span>
					<span class="product_title_remark_text">新一代体温计，APP实时监测</span>
				</div>
			</div>
		</div>

		<div class="product_content">
			<div class="product_introduce">
				<div class="product_introduce_title">产品介绍：</div>
				<div class="product_introduce_content">该设备为温度持续监测传感器，有2个型号，ZT101和ZT102，可用于患者耳温和腋温的持续监测与APP实时提醒。</div>
				<div class="product_introduce_line"></div>
				<div class="product_introduce_zt102">ZT102：适合检测腋温，可重复使用。</div>
				<div class="product_introduce_img">
					<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/img_product_show.png" alt=""/>
				</div>
				<div class="product_introduce_line"></div>
				<div class="product_introduce_zt101">ZT101：适合检测耳温，建议一次性使用。</div>
				<div class="product_introduce_img">
					<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/img_product_show_zt101.png" alt=""/>
				</div>
			</div>
			<div class="product_advantage">
				<div class="product_advantage_title">功能优势：</div>
				<div class="product_advantage_content">无线传输 | 实时监测 | 高准确度 | 高低温提醒 <br/>温度补偿 | 历史测温记录 | 温度变化曲线</div>
			</div>
			<div class="product_business">
				<div class="product_business_title">适用场景：</div>
				<div class="product_business_content">医院科室、居家旅行均适用</div>
			</div>
			<div class="product_download">
				<div class="product_download_app">
					<div class="product_download_app_title">APP下载：</div>
					<div class="product_download_app_link">
						<div class="product_download_app_link_andriod">
							<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/android_icon.png" alt=""/>
							<div class="product_download_app_link_andriod_text">
								<span class="product_download_app_link_andriod_name">
									Android
								</span>
								<span class="product_download_app_link_andriod_name_en">
									安卓手机点此下载
								</span>
							</div>
						</div>
						<div class="product_download_app_link_ios">
							<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/apple_icon.png" alt=""/>
							<div class="product_download_app_link_ios_text">
								<span class="product_download_app_link_ios_name">
									iPhone
								</span>
								<span class="product_download_app_link_ios_name_en">
									苹果手机点此下载
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="product_download_readme">
					<div class="product_download_readme_title">电子说明书下载：</div>
					<div class="product_download_readme_content">
						<a href="https://website2024.oss-cn-beijing.aliyuncs.com/pc/zt101.pdf" target="_blank" download="ZT102产品说明书.pdf">
							<div class="product_download_readme_link">
								<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/icon-download.png" alt=""/>
								<div class="product_download_readme_link_text">
								<span class="product_download_readme_link_name">
									ZT101下载
								</span>
								</div>
							</div>
						</a>
						<a href="https://website2024.oss-cn-beijing.aliyuncs.com/pc/zt102.pdf" target="_blank" download="ZT102产品说明书.pdf">
							<div class="product_download_readme_link_102">
								<img src="https://website2024.oss-cn-beijing.aliyuncs.com/pc/icon-download.png" alt=""/>
								<div class="product_download_readme_link_text">
								<span class="product_download_readme_link_name">
									ZT102下载
								</span>
								</div>
							</div>
						</a>

					</div>
				</div>
			</div>
		</div>

		<!-- footer -->

		<lr-footer id="footer" :footerLogo="logo" :companyName="companyName" :companyNameEn="companyNameEn"
			:companyTel="companyTel" :company-email="companyEmail" :companyPhone="companyPhone"></lr-footer>

		<!-- end -->
	</div>
</template>

<script>
import lrFooter from "../../components/lrfooter.vue";
import lrHeader from "../../components/lrheader.vue";


export default {
	name: "productDetail",
	components: {
		lrHeader,
		lrFooter
	},
	data() {
		return {
			logo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABECAYAAADDRGZtAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAR5SURBVGiB7Vrbdds4FJy7x//LDpYlaCtYuoIoFVipIEoFlipwUoHkCpxUILoCKQ2E3AqiNLCzH7iIQBCk+BLF5GjO8ZGJ5wye9wIARgDJhOQLyYzkfyS/kdyRXIxRf2+QjEhuWI89ydm1uVZCRWRnRFh8n6wYkk8BshuS7zVuF4iPr827AJIzj2QWIkly5aVbjc+2BgGCcU3avZNuPyLN86BZoSx2Z9IuXMVd67zrmvEM3Il7sP94PXMUkSOA3M1IMhaRQlgT/NE2Qwf8AMxeAiBz/p40Ph+ikjGEjIKbkKnhJmRquAmZGn4bIYWdXU3pCMARQK477y8B30SZAdjYD5IpgFcAn0XkgAmjMLREZAtg7QQlAB4B7NV/SEZj1hKlOSIiKxTFWCQAdlMVFJzsKuYeYYMugRH0QjK6GLOWqFy1RCQF8DeAbUWSOcyQm4SvXbv8ishRRN7B9E4aSBLD9M5icGYt0WgfEZFURO5hBH2EWZ4tIgCbax8ctPIQdbilJNcwS3UC4C8YMTMM5CR1QSdXVzfKlGSu3/mAnDqhsRCd1DMAb2DmxsyJA4xv/vZaomqF6Lh/hBlC8ZmyPolIPtaS7JhTAHAICtENzwo4hxxmEYAe/XzoS7IKujo+eLwOAO59ozGGsbXchFXIATyLyErz7WB6La9J71oMjW03PYF8QHlUrHXzLiZmM2T+vuGcFmb6nTnpixWVScZe+bEXl3nxGc0ZckHUnY7pHYqHaj5ymA3xWZfgQgM4eT/XEE5Q7OlcjdQ6zLXuA4CvAFK/fos7AEucJk3u/NoCXqtMeG2V907QlxpSCcy8s0hRbf5AGzhSDpZHXpX+TsfYqoZAHR5xaoTK1uqIRH+XNkD9o+dQT3Z2dbXF5k7Qc9eyKvAE02u+f7TReeLW3ctnf0CxN7Y9ygrhCGDjuBSpExcDeFHfKAL6CUmcCt/1KMfHz00OQExy4RmtqcdhTzLuI8SuVOuBzRIr5FV/39gIT9Aap1Xysc/9SAQj4mOPMiohIluSQevCWuFuWJ8eKe+qw2MNIGri63TukUv1hFfHVkWcNUQvdfU2GJr2+m9zZHoTMjXchEwNlxIy+lHq4ELU0eoj5Ol8kjIGFeIcWrjIz2Tzn3HMSHYSMwhoHpp99/zrhRO/8eL21gSv8M2TawnxDy+WNXHuQcLMEeOi9lXRJYVkIRKBnvDx8+UcyaUXF48tIvIIPFQQq8JO0/u9shhbyDzUkiy/W6zDLJBnU1uxg6FWrT/dD8djTFqUEVqy46aZL72zt7mn98/WWuUfSsi/7gdP94p5izLsIaB74jm6EP8k0pL51DB/6lxJBN9DjgZvkmYV4SFkzkRv/Mz2kkIWHomVhkcsPp/1RcSaLmbRMrjOhqhkMo+ou7snNM/JN9rycyduOiaKEvL3E9K8g6+0hrUnfRuttdEo/agHiS1RNsWP0KsBmDe/dlInKO8VW32kcH1UtHITrK7NvQQd97uGAjJ61wRtMfjQ8sHTlds/MMMoxsmZOiBwndcF/wO88pURM7J72gAAAABJRU5ErkJggg==",
			companyName:"哈尔滨了然科技开发有限公司",
			companyNameEn: "Harbin Liaoran Technology Development Co.",
			sysName: "竹松兰",
			companyTel: "0451-84520036",
			companyPhone: "13163662080",
			companyEmail: "0451-84520036"
		};
	},
	methods: {
		toSection(id){
			window.requestAnimationFrame(() => {
				let el = document.getElementById(id);
				this.$nextTick(function(){
					// window.scrollTo({"behavior":"smooth","top":el&&el.offsetTop});
					el.scrollIntoView({behavior: "smooth",// 平滑过渡
						block: "start"  // 上边框与视窗顶部平齐。默认值
					});
				})
			});

		}
	},
};
</script>


<style scoped>
.product_main {
	width: 100%;
	height: 9.9375rem;
	background: #E2F6E4;
	border-radius: 0px 0px 0px 0px;

	display: flex;
	align-items: center;
	justify-content: center;
}
.product_main_title {
	/* margin-top: 2.1875rem; */
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: center;
}
.product_main_img img {
	/* background: url(../../assets/img/lr/bg_app_logo.png) 100% no-repeat;
	background-size: 100% 100%; */
	width: 5.375rem;
	height: 5.375rem;
}
.product_title {
	height: 5.375rem;
	display: flex;
	flex-direction: column;
	margin-left: 2.25rem;
}

.product_title_text {
	height: 3rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 2.25rem;
	color: #3D3D3D;
	line-height: 3rem;
	text-align: center;
	font-style: normal;
	text-transform: none;
	margin-left: -1.25rem;
}
.product_title_remark_text {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: center;
	font-style: normal;
	text-transform: none;
}
</style>
<style scoped>
.product_content {
	height: 154.75rem;
	padding-left: 17rem;
	padding-right: 15rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
	background: #FFFFFF;
}
.product_introduce {
	padding-top: 3rem;
	/* height: 4.9375rem; */
	display: flex;
	flex-direction: column;
	width: 100%;
}
.product_introduce_title {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	width: 100%;
}
.product_introduce_content {
	height: 1.5rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	width: 100%;
	margin-top: 1.4375rem;
}

.product_introduce_line {
	/* width: 1218px; */
	padding-left: 10rem;
	padding-right: 15rem;
	height: 0px;
	border: 1px solid #D8D8D8;
	margin-top: 2.1875rem;
}

.product_introduce_img {
	padding: 4.6875rem;
}
.product_introduce_img img {
	width: 45.4375rem;
	height: 35.1875rem;
}

.product_advantage {
	padding-top: 2.1875rem;
	display: flex;
	flex-direction: column;
	height: 9.4375rem;
	width: 100%;
}
.product_advantage_title {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.product_advantage_content {
	height: 6rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;

	width: 25.0625rem;
	padding-top: 1.4375rem;
}

.product_business {
	/* padding-top: 2.75rem; */
	display: flex;
	flex-direction: column;
	height: 5.1875rem;
	width: 100%;
}
.product_business_title {
	/* width: 120px; */
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.product_business_content {
	/* width: 216px; */
	height: 3rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	margin-top: 1.4375rem;
}
.product_download {
	/* padding-top: 2.75rem; */
	display: flex;
	flex-direction: column;
	height: 22.9375rem;
	width: 100%;
}
.product_download_app {
	height: 9.25rem;
	margin-top: 2.75rem;
	display: flex;
	flex-direction: column;
	width: 100%;
}
.product_download_app_title {
	/* width: 122px; */
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	padding-bottom: 2.1875rem;
}
.product_download_app_link {
	display: flex;
	flex-direction: row;
	justify-content: justify-between;
}
.product_download_app_link_andriod {
	background-color: rgba(69, 132, 112, 1);
    border-radius: 10px;
    width: 15.9375rem;
    height: 5.125rem;
	display: flex;
	flex-direction: row;
}
.product_download_app_link_andriod img {
	width: 2.9375rem;
	height: 2.9375rem;
    margin: 0.875rem 0 0 1.875rem;
}
.product_download_app_link_andriod_text {
	/* height: 3.1875rem; */
	display: flex;
	flex-direction: column;
	/* justify-content: center;
	align-items: center; */
	margin-left: 0.875rem;
}
.product_download_app_link_andriod_name{
	/* width: 5.8125rem; */
	margin-top: 0.875rem;
	height: 2rem;
	overflow-wrap: break-word;
	color: rgba(255, 255, 255, 1);
	font-size: 1.5rem;
	font-family: Microsoft YaHei-Regular;
	font-weight: normal;
	text-align: left;
	white-space: nowrap;
	line-height: 2rem;
}
.product_download_app_link_andriod_name_en{
	height: 1.125rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 0.875rem;
	color: #FFFFFF;
	line-height: 1.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.product_download_app_link_ios {
	margin-left: 3rem;
	background: #535353;
	border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
	width: 15.875rem;
	display: flex;
	flex-direction: row;
}
.product_download_app_link_ios img {
	margin: 0.875rem 0 0 1.875rem;
	width: 2.375rem;
	height: 2.9375rem;
}
.product_download_app_link_ios_text {
	display: flex;
	flex-direction: column;
	margin-left: 1.25rem;
}
.product_download_app_link_ios_name {
	margin-top: 0.875rem;
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.5rem;
	color: #FFFFFF;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.product_download_app_link_ios_name_en {
	height: 1.125rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 0.875rem;
	color: #FFFFFF;
	line-height: 1.125rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.product_download_readme {
	width: 100%;
	padding-top: 3.875rem;
}
.product_download_readme_title {
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.product_download_readme_content {
	display: flex;
	flex-direction: row;
}
.product_download_readme_link {
	margin-top: 2.1875rem;
	width: 15.875rem;
	height: 5.0625rem ;
	background: #5982D0;
	border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
	display: flex;
	align-items: center;
}
.product_download_readme_link img {
	width: 3rem;
	height: 3rem;
	margin: 0.875rem 0 0 1.875rem;
}
.product_download_readme_link_102 {
	margin-left: 3rem;
	margin-top: 2.1875rem;
	width: 15.875rem;
	height: 5.0625rem ;
	background: #5982D0;
	border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
	display: flex;
	align-items: center;
}
.product_download_readme_link_102 img {
	width: 3rem;
	height: 3rem;
	margin: 0.875rem 0 0 1.875rem;
}
.product_download_readme_link_text {
	display: flex;
	flex-direction: column;
	margin-left: 1.25rem;
}
.product_download_readme_link_name {
	width: 7.5rem;
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.5rem;
	color: #FFFFFF;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.product_introduce_zt102 {
	margin-top: 2.1875rem;

	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.product_introduce_zt101 {
	margin-top: 3rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.125rem;
	color: #3D3D3D;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
</style>
