<template>
	<!-- star -->
	<div class="youjiaopage_wrap">
		
		<lr-header @toSection="toSection"></lr-header>
		
		<div class="product_top">
			<div class="product_top_title">产品中心</div>
			<div class="product_top_title_remark">了解全部产品及其功能</div>
		</div>
		
		<div class="product">
			<div class="product_content">
				<div class="product_left" @click="jumpTo('productDet')">
					<div class="product_left_content">
						<div class="product_content_title">
							<span class="product_content_title_txt">智能体温计</span>
							<br/>
							<span class="product_content_title_txt2">APP实时监测 | 高低温随时报警</span>
						</div>
					</div>
				</div>
				<div class="product_group_right">
					<div class="product_group_item" v-for="(item,index) in itemList" :key="index"
						:style="{background:'url('+item.bgPath+')  100% no-repeat', 'background-size': 'cover'}">
						<div class="product_group_item_box">
							<div class="product_group_item_text_group">
								<span class="product_group_item_text">{{item.title}}</span>
								<br/>
								<span class="product_group_item_text2">{{item.content}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- footer -->
		
		<lr-footer id="footer" :footerLogo="logo" :companyName="companyName" :companyNameEn="companyNameEn"
			:companyTel="companyTel" :company-email="companyEmail" :companyPhone="companyPhone"></lr-footer>
			
		<!-- end -->
	</div>
</template>

<script>
import lrFooter from "../../components/lrfooter.vue";
import lrHeader from "../../components/lrheader.vue";


export default {
	name: "product",
	components: {
		lrHeader,
		lrFooter
	},
	data() {
		return {
			itemList:[{
				bgPath: require('../../assets/img/lr/bg_product_item.png'),
				title: "智能体温计",
				content: "APP实时监测 | 高低温随时报警"
			},{
				bgPath:require('../../assets/img/lr/bg_product_item.png'),
				title: "智能体温计",
				content: "APP实时监测 | 高低温随时报警"
			},{
				bgPath:require('../../assets/img/lr/bg_product_item.png'),
				title: "智能体温计",
				content: "APP实时监测 | 高低温随时报警"
			},{
				bgPath:require('../../assets/img/lr/bg_product_item.png'),
				title: "智能体温计",
				content: "APP实时监测 | 高低温随时报警"
			}],
			logo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABECAYAAADDRGZtAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAR5SURBVGiB7Vrbdds4FJy7x//LDpYlaCtYuoIoFVipIEoFlipwUoHkCpxUILoCKQ2E3AqiNLCzH7iIQBCk+BLF5GjO8ZGJ5wye9wIARgDJhOQLyYzkfyS/kdyRXIxRf2+QjEhuWI89ydm1uVZCRWRnRFh8n6wYkk8BshuS7zVuF4iPr827AJIzj2QWIkly5aVbjc+2BgGCcU3avZNuPyLN86BZoSx2Z9IuXMVd67zrmvEM3Il7sP94PXMUkSOA3M1IMhaRQlgT/NE2Qwf8AMxeAiBz/p40Ph+ikjGEjIKbkKnhJmRquAmZGn4bIYWdXU3pCMARQK477y8B30SZAdjYD5IpgFcAn0XkgAmjMLREZAtg7QQlAB4B7NV/SEZj1hKlOSIiKxTFWCQAdlMVFJzsKuYeYYMugRH0QjK6GLOWqFy1RCQF8DeAbUWSOcyQm4SvXbv8ishRRN7B9E4aSBLD9M5icGYt0WgfEZFURO5hBH2EWZ4tIgCbax8ctPIQdbilJNcwS3UC4C8YMTMM5CR1QSdXVzfKlGSu3/mAnDqhsRCd1DMAb2DmxsyJA4xv/vZaomqF6Lh/hBlC8ZmyPolIPtaS7JhTAHAICtENzwo4hxxmEYAe/XzoS7IKujo+eLwOAO59ozGGsbXchFXIATyLyErz7WB6La9J71oMjW03PYF8QHlUrHXzLiZmM2T+vuGcFmb6nTnpixWVScZe+bEXl3nxGc0ZckHUnY7pHYqHaj5ymA3xWZfgQgM4eT/XEE5Q7OlcjdQ6zLXuA4CvAFK/fos7AEucJk3u/NoCXqtMeG2V907QlxpSCcy8s0hRbf5AGzhSDpZHXpX+TsfYqoZAHR5xaoTK1uqIRH+XNkD9o+dQT3Z2dbXF5k7Qc9eyKvAE02u+f7TReeLW3ctnf0CxN7Y9ygrhCGDjuBSpExcDeFHfKAL6CUmcCt/1KMfHz00OQExy4RmtqcdhTzLuI8SuVOuBzRIr5FV/39gIT9Aap1Xysc/9SAQj4mOPMiohIluSQevCWuFuWJ8eKe+qw2MNIGri63TukUv1hFfHVkWcNUQvdfU2GJr2+m9zZHoTMjXchEwNlxIy+lHq4ELU0eoj5Ol8kjIGFeIcWrjIz2Tzn3HMSHYSMwhoHpp99/zrhRO/8eL21gSv8M2TawnxDy+WNXHuQcLMEeOi9lXRJYVkIRKBnvDx8+UcyaUXF48tIvIIPFQQq8JO0/u9shhbyDzUkiy/W6zDLJBnU1uxg6FWrT/dD8djTFqUEVqy46aZL72zt7mn98/WWuUfSsi/7gdP94p5izLsIaB74jm6EP8k0pL51DB/6lxJBN9DjgZvkmYV4SFkzkRv/Mz2kkIWHomVhkcsPp/1RcSaLmbRMrjOhqhkMo+ou7snNM/JN9rycyduOiaKEvL3E9K8g6+0hrUnfRuttdEo/agHiS1RNsWP0KsBmDe/dlInKO8VW32kcH1UtHITrK7NvQQd97uGAjJ61wRtMfjQ8sHTlds/MMMoxsmZOiBwndcF/wO88pURM7J72gAAAABJRU5ErkJggg==",
			companyName:"哈尔滨了然科技开发有限公司",
			companyNameEn: "Harbin Liaoran Technology Development Co.",
			sysName: "竹松兰",
			companyTel: "0451-84520036",
			companyPhone: "13163662080",
			companyEmail: "0451-84520036"
		};
	},
	methods: {
		jumpTo(url){
			this.$router.push(url);
		},
		toSection(id){
			window.requestAnimationFrame(() => {
				let el = document.getElementById(id);
				this.$nextTick(function(){
					// window.scrollTo({"behavior":"smooth","top":el&&el.offsetTop});
					el.scrollIntoView({behavior: "smooth",// 平滑过渡
						block: "start"  // 上边框与视窗顶部平齐。默认值
					});
				})
			});
			
		}
	},
};
</script>


<style scoped>

.product {
	padding-left: 17rem;
	padding-right: 15rem;
	/* width: 100%; */
	background-color: rgb(248, 248, 248);
	height: 49.0625rem;
	
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.product_top {
	width: 100%;
	height: 18.75rem;
	background: url(../../assets/img/lr/bg_product_top.png) 100% no-repeat;
	background-size: 100% 100%;
	
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.product_top_title {
	/* width: 12rem; */
	height: 3.9375rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 3rem;
	color: #3D3D3D;
	line-height: 3.9375rem;
	text-align: center;
	font-style: normal;
	text-transform: none;
}
.product_top_title_remark {
	/* width: 15rem; */
	height: 2rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.5rem;
	color: #3D3D3D;
	line-height: 2rem;
	text-align: center;
	font-style: normal;
	text-transform: none;
}

.product_content {
	width: 90rem;
	height: 35.3125rem;
	
	/* align-items: center;
	justify-content: center; */
	display: flex;
	flex-direction: row;
}

.product_left {
	/* background: linear-gradient( 180deg, rgba(52,103,203,0) 0%, #3656B6 83%); */
	height: 35.3125rem;
	border-radius: 0px 0px 0px 0px;
	width: 33%;
	background: url(../../assets/img/lr/bg_prodct_content.png) 100% no-repeat;
	background-size: 100% 100%;
	cursor: pointer;
}
.product_left_content {
	background: linear-gradient( 180deg, rgba(52,103,203,0) 0%, #3656B6 83%);
	margin-top: 31.0625rem;
	height: 4.25rem;
	border-radius: 0px 0px 0px 0px;
}
.product_content_title {
	margin-top: 4.5625rem;
	margin-left: 1.1rem;
}
.product_content_title_txt{
	width: 100%;
	height: 100%;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #FFFFFF;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.product_content_title_txt2 {
	margin-top: 0.425rem;
	width: 100%;
	height: 1.5rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.1rem;
	color: #FFFFFF;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
	display: flex;
}

.product_group_right {
	margin-left: 1.5625rem;
	width: 67%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.product_group_item {
	width: 48%;
	height: 16.875rem;
	/* background: #FFFFFF; */
	border-radius: 0px 0px 0px 0px;
	background-size: 100% 100%;
}
.product_group_item_box {
	height: 4.125rem;
	margin-top: 13.35rem;
	background-image: linear-gradient( 180deg, rgba(26, 51, 182, 0) 0, rgba(30, 70, 191, 1) 83.291394% );
}
.product_group_item_text_group {
	margin: 20% 0 0 5%;
}
.product_group_item_text {
	width: 100%;
	height: 100%;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 700;
	font-size: 1.5rem;
	color: #FFFFFF;
	line-height: 2rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}

.product_group_item_text2{
	
	margin-top: 0.425rem;
	width: 100%;
	height: 1.5rem;
	font-family: Microsoft YaHei, Microsoft YaHei;
	font-weight: 400;
	font-size: 1.125rem;
	color: #FFFFFF;
	line-height: 1.5rem;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
</style>